import { Controller } from "stimulus"

export default class extends Controller {

  track(e){
    let jsonProperties

    let event          = e.currentTarget.dataset.amplitudeEvent;
    let properties     = e.currentTarget.dataset.amplitudeProperties;
    let userProperties = e.currentTarget.dataset.amplitudeUserprops;
    let eventSubtype   = e.currentTarget.dataset.eventSubtype;
    let userId         = properties ? JSON.parse(properties).amplitude_id : "";

    const userDevice = document.getElementById("device-properties")?.dataset["properties"];
    let userDeviceProperties = this.parseJSONProperties(userDevice);

    if (userDeviceProperties && userDeviceProperties.saverlife_user_agent && userDeviceProperties.saverlife_user_agent.includes("Amazon-Route53-Health-Check-Service")) {
      return;
    }

    if (properties)
      jsonProperties = JSON.parse(properties)

    if (userProperties)
      amplitude.getInstance().setUserProperties(userProperties)

    if (userId)
      amplitude.getInstance().setUserId(userId)

    if (userDeviceProperties) {
      userDeviceProperties = new amplitude.Identify()
      .set('saverlife_platform', userDeviceProperties.saverlife_platform)
      .set('saverlife_mobile_version', userDeviceProperties.saverlife_mobile_version);
      amplitude.getInstance().identify(userDeviceProperties);
    }
    let newTagElement = e.currentTarget.querySelectorAll(".c-card-activity__tag")[0];

    if (newTagElement) {
      newTagElement.style.display = "none";
    }

    amplitude.getInstance().logEvent(
      event,
      jsonProperties,
      () => logAmplitudeEvent(event, jsonProperties, eventSubtype)
      );
  }

  parseJSONProperties(data){
    try {
      return JSON.parse(data);
    } catch(error){
      return null;
    }
  }
}
