// TODO: WE HAVE TO REFACTOR THIS CONTROLLER.
import { Controller } from "stimulus"
export default class extends Controller {
  static get targets() {
    return ["option", "submit"]
  }

  connect() {
    this.setSubmitUrl(this.submitTarget.dataset.submiturl)
  }

  select(event) {
    this.setSelection(event.currentTarget.dataset.value)
    this.toggleSelectionClass(event.currentTarget)
    // note: not a fan of this
    this.enableButton()
  }

  toggleSelectionClass(target) {
    this.optionTargets.forEach((option) => {
      option.classList.remove('c-card-option--selected')
    })
    target.classList.add('c-card-option--selected')
  }

  enableButton() {
    this.submitTarget.removeAttribute('disabled')
  }

  submit() {
    let url = `${this.submitUrl}${this.selection}`

    Rails.default.ajax({
      url: url,
      type: "patch",
      success: function(data) {
        window.location.href = data.redirect_url;
      },
      error: function(data) {
        console.log("error: ", data)
        flash_message("warning", data.error)
      }})
  }

  setSelection(value) {
    this.selection = value
  }

  setSubmitUrl(value) {
    this.submitUrl = value
  }
}
